<template>
    <div class="buah_kita_page">
        <Header />
        <div class="main_img_list">
            <img class="main_img" src="../../src/assets/buahKita/1.png" alt="">
            <img class="main_img" src="../../src/assets/buahKita/2.png" alt="">
            <img class="main_img" src="../../src/assets/buahKita/3.png" alt="">
            <img class="main_img" src="../../src/assets/buahKita/4.png" alt="">
            <img class="main_img" src="../../src/assets/buahKita/5.png" alt="">
            <img class="main_img" src="../../src/assets/buahKita/6.png" alt="">
        </div>
        <Contact />
    </div>
</template>

<script>
import Header from "@/components/pc/Header.vue";
import Contact from "@/components/pc/Contact.vue";
export default {
    components: {
        Header,
        Contact,
    },
    data() {
        return {

        };
    },
};
</script>

<style lang="scss" scoped>
.buah_kita_page {
    width: 100%;
    .main_img_list {
        margin: 0 auto;
        margin-top: 64px;
        text-align: center;
        .main_img {
            width: 1040px;
            display: block;
            margin: 0 auto;
            margin-bottom: 10px;
        }
    }
    /deep/ #contact {
        padding-top: 10px;
    }
    /deep/ #contact::before {
        height: 0;
        margin-top: 0;
    }
}
</style>
